import React from 'react';
import { ReactComponent as RefreshIcon } from 'assets/images/RefreshIcon.svg';
import { ReactComponent as NoInternetIcon } from 'assets/images/NoInternetIcon.svg';

const NoInternet = () => {
  const handleRequest = () => {
    window.location.reload();
  };
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="text-center">
        <div className="no-data-img">
          <NoInternetIcon />
        </div>
        <h5 className="my-4">Slow or no internet connection. Please check your connection and try again.</h5>
        <button className="btn btn-primary" onClick={handleRequest}>
          Refresh <RefreshIcon />
        </button>
      </div>
    </div>
  );
};

export default NoInternet;
