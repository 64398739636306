import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CompanyContextProvider } from './Context/CompanyContext';
import { AuthContextProvider } from './Context/AuthContext';
import { ThemeContextProvider } from './Context/ThemeContext';
import { BrowserRouter } from 'react-router-dom';
import { AxiosInterceptor } from './Helper/api_helper';
import { CookiesProvider } from 'react-cookie';
import { SocketContextProvider } from './Context/SocketContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.Fragment>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <CompanyContextProvider>
          <AuthContextProvider>
            <ThemeContextProvider>
              <AxiosInterceptor>
                <SocketContextProvider>
                  <App />
                </SocketContextProvider>
              </AxiosInterceptor>
            </ThemeContextProvider>
          </AuthContextProvider>
        </CompanyContextProvider>
      </BrowserRouter>
    </CookiesProvider>
  </React.Fragment>,
);
