import { createContext, useReducer, useContext } from 'react';
export const CompanyContext = createContext();

export const companyReducer = (state, action) => {
  switch (action.type) {
    case 'SET_COMPANY':
      return { company: action.payload };
    case 'REMOVE_COMPANY':
      return { company: null };
    default:
      return state;
  }
};

export const CompanyContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(companyReducer, {});

  return <CompanyContext.Provider value={{ ...state, dispatch }}>{children}</CompanyContext.Provider>;
};

export const useCompanyContext = () => {
  const context = useContext(CompanyContext);
  if (!context) {
    throw Error('useAuthContext must be used inside an AuthContextProvider');
  }
  return context;
};
