import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import io from 'socket.io-client';
import { useCompanyContext } from './CompanyContext';

export const SocketContext = createContext();

export const socketReducer = (state, action) => {
  switch (action.type) {
    case 'CONNECT':
      return { ...state, socket: action.payload };
    case 'DISCONNECT':
      return { ...state, socket: null };
    default:
      return state;
  }
};

export const SocketContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(socketReducer, { socket: null });
  const [{ token }] = useCookies(['token']);
  const { pathname } = useLocation();
  const { company } = useCompanyContext();

  useEffect(() => {
    if (!pathname.includes('admin/live-status') && !pathname.includes('admin/user/screenshot')) return;
    if (token && company) {
      const socket = io(process.env.REACT_APP_BASE_URL, {
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: `Bearer ${token}`,
            },
          },
        },
        query: {
          roomId: company.id,
        },
      });

      socket.on('connect', () => {
        dispatch({ type: 'CONNECT', payload: socket });
      });

      socket.on('disconnect', () => {
        dispatch({ type: 'DISCONNECT' });
      });
      
      return () => {
        socket.disconnect();
      };
    }
  }, [token, pathname, company]);

  return <SocketContext.Provider value={{ socket: state.socket }}>{children}</SocketContext.Provider>;
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw Error('useSocketContext must be used inside a SocketContextProvider');
  }
  return context;
};
