import { get, post } from './api_helper';

export const checkSession = function (workspace) {
  return get(`/check-session/${workspace}`);
};

export const switchUser = function () {
  return get(`/switch-user`);
};

export const getWorkspace = function (params) {
  return post('/get-workspace', params);
};

export const switchUserSuperAdmin = function (params) {
  return post('/switch-user-super', params);
};

export const revertToSuperAdmin = function () {
  return get('/switch-to-superuser');
};

export const checkAvailableWorkspace = function (params) {
  return post('/check-available-workspace', params);
};

export const login = function (params) {
  return post('/login', params);
};
export const forgotPassword = function (params) {
  return post('/forgot-password', params);
};
export const getResetPassword = function (token) {
  return get(`/reset-password/${token}`);
};
export const postResetPassword = function (params) {
  return post('/reset-password', params);
};
export const accountSignup = function (params) {
  return post('/signup', params);
};

export const getTwoStepQrCode = function (params) {
  return post('/get-two-step-auth-qr-code', params);
};
export const enableTwoStepAuth = function (params) {
  return post('/enable-two-step-auth', params);
};
export const regeneratedCodes = function () {
  return get('/regenerate-backcodes');
};
export const disableTwoStepAuth = function (params) {
  return post('/disable-two-step-auth', params);
};
export const verifyTwoStepAuth = function (params) {
  return post('/verify-two-step-auth', params);
};

export const signUpTokenVerify = function (token) {
  return get(`/sign-up/${token}`);
};
