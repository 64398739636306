import React, { useEffect, useState } from 'react';
import './assets/scss/themes.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import AllRoutes from './AllRoutes';
import { ToastContainer } from 'react-toastify';
import NoInternet from 'Components/Common/ListLoaders/NoInternet';

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, []);
  return (
    <React.Fragment>
      {!isOnline ? (
        <NoInternet />
      ) : (
        <>
          <AllRoutes />
          <ToastContainer />
        </>
      )}
    </React.Fragment>
  );
}

export default App;
