import React, { lazy, Suspense } from 'react';

const AdminDashboard = lazy(() => import('Pages/Admin/Dashboard'));
const AdminProjects = lazy(() => import('Pages/Admin/Projects'));
const AdminViewProject = lazy(() => import('Pages/Admin/ViewProject'));
const AdminUsers = lazy(() => import('Pages/Admin/Users'));
const AdminViewUser = lazy(() => import('Pages/Admin/ViewUser'));
const AdminClients = lazy(() => import('Pages/Admin/Clients'));
const AdminViewClient = lazy(() => import('Pages/Admin/ViewClient'));
const AdminReports = lazy(() => import('Pages/Admin/Reports'));
const AdminLiveStatus = lazy(() => import('Pages/Admin/LiveStatus'));
const AdminScreenShots = lazy(() => import('Pages/Admin/ScreenShots'));
const AdminCalendar = lazy(() => import('Pages/Admin/Calendar'));
const ActivityLogs = lazy(() => import('Pages/Admin/ActivityLogs'));
const AdminInvoices = lazy(() => import('Pages/Admin/Invoices'));
const AddInvoice = lazy(() => import('Pages/Admin/AddInvoice'));
const CompanyProfile = lazy(() => import('Pages/Admin/CompanyProfile'));
const Settings = lazy(() => import('Pages/Admin/Settings'));

const ManagerDashboard = lazy(() => import('Pages/Manager/Dashboard'));
const ManagerUsers = lazy(() => import('Pages/Manager/Users'));
const ManagerViewUser = lazy(() => import('Pages/Manager/ViewUser'));
const ManagerProjects = lazy(() => import('Pages/Manager/Projects'));
const ManagerViewProject = lazy(() => import('Pages/Manager/ViewProject'));
const ManagerClients = lazy(() => import('Pages/Manager/Clients'));
const ManagerViewClient = lazy(() => import('Pages/Manager/ViewClient'));
const ManagerReports = lazy(() => import('Pages/Manager/Reports'));
const ManagerLiveStatus = lazy(() => import('Pages/Manager/LiveStatus'));
const ManagerScreenShots = lazy(() => import('Pages/Manager/ScreenShots'));

const UserDashboard = lazy(() => import('Pages/User/Dashboard'));
const UserReports = lazy(() => import('Pages/User/Reports'));
const UserProjects = lazy(() => import('Pages/User/Projects'));
const UserViewProject = lazy(() => import('Pages/User/ViewProject'));
const UserClients = lazy(() => import('Pages/User/Clients'));
const UserViewClient = lazy(() => import('Pages/User/ViewClient'));
const UserCalendar = lazy(() => import('Pages/User/Calendar'));
const UserProfile = lazy(() => import('Pages/Common/Profile'));

const SuperAdminFormsSubmitted = lazy(() => import('Pages/SuperAdmin/FormsSubmitted/FormsSubmitted'));
const SuperAdminUploadApps = lazy(() => import('Pages/SuperAdmin/UploadApps'));
const SuperAdminDashboardMain = lazy(() => import('Pages/SuperAdmin/Dashboard'));
const SuperAdminCompanyProfile = lazy(() => import('Pages/SuperAdmin/ViewCompanyProfile/ViewCompanyProfile'));
const SuperAdminSettings = lazy(() => import('Pages/SuperAdmin/Settings/Settings'));
const SuperAdminAppErrors = lazy(() => import('Pages/SuperAdmin/AppErrors'));

const SuperAdminRoutes = [
  {
    path: 'dashboard',
    element: (
      <Suspense>
        <SuperAdminDashboardMain />
      </Suspense>
    ),
  },
  {
    path: 'forms-submitted',
    element: (
      <Suspense>
        <SuperAdminFormsSubmitted />
      </Suspense>
    ),
  },
  {
    path: 'upload-apps',
    element: (
      <Suspense>
        <SuperAdminUploadApps />
      </Suspense>
    ),
  },
  {
    path: 'companyprofile/:id',
    element: (
      <Suspense>
        <SuperAdminCompanyProfile />
      </Suspense>
    ),
  },
  {
    path: 'settings',
    element: (
      <Suspense>
        <SuperAdminSettings />
      </Suspense>
    ),
  },
  {
    path: 'app-errors',
    element: (
      <Suspense>
        <SuperAdminAppErrors />
      </Suspense>
    ),
  },
];

const AdminRoutes = [
  {
    path: 'dashboard',
    element: (
      <Suspense>
        <AdminDashboard />
      </Suspense>
    ),
  },
  {
    path: 'users',
    element: (
      <Suspense>
        <AdminUsers />
      </Suspense>
    ),
  },
  {
    path: 'view-user/:id',
    element: (
      <Suspense>
        <AdminViewUser />
      </Suspense>
    ),
  },
  {
    path: 'projects',
    element: (
      <Suspense>
        <AdminProjects />
      </Suspense>
    ),
  },
  {
    path: 'view-project/:id',
    element: (
      <Suspense>
        <AdminViewProject />
      </Suspense>
    ),
  },
  {
    path: 'companyprofile',
    element: (
      <Suspense>
        <CompanyProfile />
      </Suspense>
    ),
  },
  {
    path: 'clients',
    element: (
      <Suspense>
        <AdminClients />
      </Suspense>
    ),
  },
  {
    path: 'view-client/:id',
    element: (
      <Suspense>
        <AdminViewClient />
      </Suspense>
    ),
  },
  {
    path: 'reports',
    element: (
      <Suspense>
        <AdminReports />
      </Suspense>
    ),
  },
  {
    path: 'invoices',
    element: (
      <Suspense>
        <AdminInvoices />
      </Suspense>
    ),
  },
  {
    path: 'add-invoice',
    element: (
      <Suspense>
        <AddInvoice />
      </Suspense>
    ),
  },
  {
    path: 'edit-invoice/:id',
    element: (
      <Suspense>
        <AddInvoice />
      </Suspense>
    ),
  },
  {
    path: 'live-status',
    element: (
      <Suspense>
        <AdminLiveStatus />
      </Suspense>
    ),
  },
  {
    path: 'user/screenshot/:id',
    element: (
      <Suspense>
        <AdminScreenShots />
      </Suspense>
    ),
  },
  {
    path: 'activity-logs',
    element: (
      <Suspense>
        <ActivityLogs />
      </Suspense>
    ),
  },
  {
    path: 'calendar',
    element: (
      <Suspense>
        <AdminCalendar />
      </Suspense>
    ),
  },
  {
    path: 'settings',
    element: (
      <Suspense>
        <Settings />
      </Suspense>
    ),
  },
];

const ManagerRoutes = [
  {
    path: 'dashboard',
    element: (
      <Suspense>
        <ManagerDashboard />
      </Suspense>
    ),
  },
  {
    path: 'users',
    element: (
      <Suspense>
        <ManagerUsers />
      </Suspense>
    ),
  },
  {
    path: 'view-user/:id',
    element: (
      <Suspense>
        <ManagerViewUser />
      </Suspense>
    ),
  },
  {
    path: 'projects',
    element: (
      <Suspense>
        <ManagerProjects />
      </Suspense>
    ),
  },
  {
    path: 'view-project/:id',
    element: (
      <Suspense>
        <ManagerViewProject />
      </Suspense>
    ),
  },
  {
    path: 'clients',
    element: (
      <Suspense>
        <ManagerClients />
      </Suspense>
    ),
  },
  {
    path: 'view-client/:id',
    element: (
      <Suspense>
        <ManagerViewClient />
      </Suspense>
    ),
  },
  {
    path: 'reports',
    element: (
      <Suspense>
        <ManagerReports />
      </Suspense>
    ),
  },
  {
    path: 'live-status',
    element: (
      <Suspense>
        <ManagerLiveStatus />
      </Suspense>
    ),
  },
  {
    path: 'user/screenshot/:id',
    element: (
      <Suspense>
        <ManagerScreenShots />
      </Suspense>
    ),
  },
  {
    path: 'calendar',
    element: (
      <Suspense>
        <UserCalendar />
      </Suspense>
    ),
  },
];

const UserRoutes = [
  {
    path: 'dashboard',
    element: (
      <Suspense>
        <UserDashboard />
      </Suspense>
    ),
  },
  {
    path: 'reports',
    element: (
      <Suspense>
        <UserReports />
      </Suspense>
    ),
  },
  {
    path: 'profile',
    element: (
      <Suspense>
        <UserProfile />
      </Suspense>
    ),
  },
  {
    path: 'projects',
    element: (
      <Suspense>
        <UserProjects />
      </Suspense>
    ),
  },
  {
    path: 'view-project/:id',
    element: (
      <Suspense>
        <UserViewProject />
      </Suspense>
    ),
  },
  {
    path: 'clients',
    element: (
      <Suspense>
        <UserClients />
      </Suspense>
    ),
  },
  {
    path: 'view-client/:id',
    element: (
      <Suspense>
        <UserViewClient />
      </Suspense>
    ),
  },
  {
    path: 'calendar',
    element: (
      <Suspense>
        <UserCalendar />
      </Suspense>
    ),
  },
];

export { SuperAdminRoutes, AdminRoutes, ManagerRoutes, UserRoutes };
