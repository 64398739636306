import { useCompanyContext } from 'Context/CompanyContext';
import 'react-toastify/dist/ReactToastify.css';
export const useCompany = () => {
  const { dispatch } = useCompanyContext();
  const setCompany = (company) => {
    if (company) {
      dispatch({ type: 'SET_COMPANY', payload: company });
    } else {
      dispatch({ type: 'REMOVE_COMPANY', payload: null });
      //   window.location.href = 'https://app.jellyfishtechnologies.dev/account-signup';
    }
  };
  return { setCompany };
};
